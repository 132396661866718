/*
	Interactivity for the profile wizard.
	Favourite stores step is handled by favourite-stores.js
	@author Matthew Norris
*/

'use strict';

const AjaxFormErrorHandler = require('Scripts/common/ajax-form-error-handler');
const bsCollapse           = require('Scripts/common/bs-collapse');
const fetchJson            = require('Scripts/common/fetch-json').default;
const ImageUploadCrop      = require('Scripts/common/image-upload-crop').default;
const LoadingButton        = require('Scripts/common/loading-button');
const showErrorBar         = require('Scripts/common/show-error-bar').default;

module.exports = Profile;

function Profile() {
	this.baseUrl = "/account/profile";

	this.init = function() {
		self.errorHandler = new AjaxFormErrorHandler();
		self.scorebar = new ProfileScorebar();
		$(window).on("popstate", function() { self.setStepFromUrl(false); }); // This is the back button catcher
		self.renderScore(JSON.parse($("#completion-json").text()))
		self.bindStepLinks();
		self.setStepFromUrl(true);
		self.bindStepsJs();

		this.image = new ImageUploadCrop({
			width:     500,
			height:    500,
			restrict:  true,
			container: $('.js-image-container'),
			modal:     $("#js-modal-image-crop"),
		});
	};

	// Render user's profile completion score
	// This is using a built-in bootstrap progress bar widget
	this.renderScore = function(completionData) {
		console.log("Profile.renderScore()");

		self.scorebar.setScore(completionData.totPercent);

		// Change nav links to green+ticked or grey+unticked, depending on whether the particular section is complete
		$("#wizard-progress a").each(function() {
			var el = $(this);
			var icon = el.find(".fa-solid");

			var complete = completionData.sectionScores[el.data("step")] == 1;

			el.toggleClass("text-green", complete);
			icon.toggleClass("fa-circle", !complete);
			icon.toggleClass("fa-circle-check", complete);
		});
	};

	this.bindStepLinks = function() {
		$(".step-link").each(function() {
			$(this).on("click", function() {
				self.setStep($(this).data("step"), true);
			});
		});
		$(".step-skip").each(function() {
			$(this).on("click", function() {
				var stepId = $(this).closest(".wizard-step").attr("id").split("_").pop();
				self.gotoNextStep(stepId);
			});
		});
	};

	this.setStepFromUrl = function(pushState) {
		var step = window.location.pathname.split("/").pop();
		if (step == "" || step == "profile") { // if at root path, set step to the first one
			step = "about";
		}
		self.setStep(step, pushState);
	};

	// The custom JS for individual steps
	this.bindStepsJs = function() {
		self.bindShoppingHabits();
	}

	// Shopping habits involves some extra questions that reveal themselves after other questions get answered
	this.bindShoppingHabits = function() {
		self.bindShowYesRadio("shoppingHabits.shopAmazon", "reveal_amazon");
		self.bindShowYesRadio("shoppingHabits.businessPurchases", "reveal_business");
	}

	// Set a particular step to active
	this.setStep = function(currentStep, pushState) {
		console.log("Profile.setStep()", currentStep, pushState);

		// Push the URL corresponding to the step, but only if browser supports it
		if (pushState && window.history.pushState) {
			var newUrl = self.baseUrl+"/"+currentStep;
			window.history.pushState({state: "new"}, "", newUrl);
		}

		// Change the step links to reflect the step
		var reachedCurrent = false;
		$("#wizard-progress a").each(function() {
			var el = $(this);
			var elStep = el.data("step");
			
			el.toggleClass("active", elStep == currentStep);
		});

		// Show the current current step, hide the other step
		$(".wizard-step").each(function() {
			var el = $(this);
			var id = el.attr("id");
			if (id == "wizard-step_"+currentStep) {
				self.enableSubmit(currentStep);
				bsCollapse(el).show();
				$('html, body').scrollTop(0);
			} else {
				self.disableSubmit(id.split("_").pop());
				bsCollapse(el).hide();
			}
		});
	}

	// Enable the submit button for a particular step
	this.enableSubmit = function(currentStep) {
		console.log("Profile.enableSubmit()", currentStep);

		var panel = $("#wizard-step_" + currentStep);
		panel.find(":submit").removeClass("disabled");

		var form = panel.find("form");
		form.off().on("submit", function() {
			// self.disableSubmit(currentStep);
			self.submitStep($(this), currentStep);
			return false;
		});		
	}

	// Disable the submit button for a particular step
	this.disableSubmit = function(currentStep) {
		console.log("Profile.disableSubmit()", currentStep);

		var panel = $("#wizard-step_" + currentStep);
		panel.find(".form-step").off();
		panel.find(":submit").addClass("disabled");
	}

	// Submit the form for a particular step.
	this.submitStep = async function(form, step) {
		console.log("Profile.submitStep()");

		const btn = new LoadingButton($("#wizard-step_" + step + " :submit"));
		btn.disable();

		const url = self.baseUrl+"/"+step;

		let formData = new FormData(form.get(0));

		// Add profile picture if on 'about' step
		if(step == '' || step == 'about') {
			formData = this.image.addImagesToFormData(formData, "images");
		}

		const { success, result } = await fetchJson(url, { 
			method: 'POST',
			body: formData,
		});

		if(!success) {
			showErrorBar();
			return;
		}

		if (this.errorHandler.handleErrors(result.validation)) {
			bsCollapse($("#oops_"+step)).show();
			this.enableSubmit(step);
		} else {
			bsCollapse($("#oops_"+step)).hide();
			this.renderScore(result.completionScores);
			this.gotoNextStep(step);
		}

		btn.enable();
	}

	// Determine the next step by lookin in the DOM
	this.gotoNextStep = function(currentStep) {
		console.log("Profile.gotoNextStep()");

		var nextStepElement = $("#wizard-step_"+currentStep).next(".wizard-step");

		if (nextStepElement.length > 0) {
			var nextStep = nextStepElement.attr("id").split("_").pop();
			self.setStep(nextStep, true);
		} 
		else {
			window.location.href = "/account/profile/complete";
		}
	}

	// bind shopping habits radio handlers to show moar stuff when "yes" is picked
	this.bindShowYesRadio = function(radioName, revealId) {
		$('input:radio[name="'+radioName+'"]').change(function() {
			var el = $(this);
			bsCollapse($("#"+revealId)).toggle(el.is(":checked") && el.val() == "1");
		});
	}

	var self = this;
	this.init();
}

var ProfileScorebar = function() {
	this.setScore = function(percent) {
		console.log("Setting progress to " + percent + "%");

		$("#profile-progress-bar").css("width", percent+"%")
		$("#profile-score-percentage").text(percent+"%");
	}
}